import React, { useContext } from "react";
import Styles from "./CoinUsageDescription.module.scss";
// Components
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
// Constants
import { NoSuccessErrorHandler } from "@vahak/core/dist/constants";
import { AppContext } from "@vahak/core/dist/app-context";
// Methods
// Hooks
import { useGetCashbackLimit } from "../../../../../services/payment/useGetCashbackLimit";
// Assets
import CoinIcon from "@vahak/core/dist/standard-icons/coin.svg";
// =============================== * =============================== * =============================== //

interface CoinUsageDescriptionProps {
    bookingAmount: number;
}

const CoinUsageDescription = ({ bookingAmount }: CoinUsageDescriptionProps) => {
    const { coinsAvailable = 0 } = useContext(AppContext);

    const cashbackLimitResponse = useGetCashbackLimit(
        {},
        {
            ...NoSuccessErrorHandler,
            refetchOnWindowFocus: false,
            enabled: !!coinsAvailable
        }
    );

    const usableCoin = Math.floor(
        Math.min(coinsAvailable, bookingAmount) * (cashbackLimitResponse?.data?.data?.cashback_usage_percentage ?? 1)
    );

    if (!usableCoin) {
        return null;
    }
    return (
        <div className={Styles.main}>
            <CoinIcon />
            Use up to {usableCoin} coins for instant discount on booking
        </div>
    );
};

export default CoinUsageDescription;
