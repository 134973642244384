import React, { useEffect, useRef } from "react";
import Styles from "./AssuredPaymentConfirmation.module.scss";
import { FormikProps } from "formik";
// Components
import HowAssuredLoadWorksSteps from "./HowAssuredLoadWorksSteps";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import ExpectedPrice from "../../../../posting-form/common/expected-price/ExpectedPrice";
// Constants
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { IEventTrackerProp } from "../../../../../types/events";
import { LoadMarketCard } from "../../../../../services/load/useGetLoadMarketV2/type";
import { BiddingFormValues } from "../../common/type";
import { LoadBidFormFieldNames } from "../../common/fieldNames";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { IDs } from "@vahak/core/dist/constants/automation";
import { NoSuccessErrorHandler } from "@vahak/core/dist/constants/api";
// Methods
import { getPriceString } from "@vahak/core-ui/dist/methods/common";
import { removeNonNumeric } from "@vahak/core-ui/dist/methods/string";
// Hooks
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import { useGetPriceRecommendation } from "../../../../../services/posting/useGetPriceRecommendation";
// Assets
import CheckIcon from "@vahak/core/dist/icons/tick_white.svg";
import CrossIcon from "@vahak/core/dist/standard-icons/cross-icon.svg";
import { scrollToElement } from "@vahak/core/dist/methods/scrollToElement";
import BordererCard from "@vahak/core-ui/dist/components/common/BordererCard/BordererCard";
import AssuredLoadBestPrice from "@vahak/core/dist/icons/ribbon-best-price--lg.svg";
import Checkbox from "@vahak/core-ui/dist/components/Checkbox";
import Chip from "@vahak/core-ui/dist/components/Chip";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import CoinUsageDescription from "../payment/CoinUsageDescription";

interface AssuredPaymentConfirmationProps extends IEventTrackerProp {
    onClickBid?: () => void;
    bidPrice?: number;
    additionalCharges?: number;
    closeBidForm?: () => void;
    disableBtn?: boolean;
    companyId?: number;
    isTapNgoLoad?: boolean;
    isSpotLoad?: boolean;
    isPaidBookingRequired?: boolean;
    formikConfig?: FormikProps<BiddingFormValues>;
    loadData?: LoadMarketCard;
    bookingAmount?: number;
    enablePriceSuggestion?: boolean;
}

const AssuredPaymentConfirmation = ({
    onClickBid,
    bidPrice,
    closeBidForm,
    disableBtn,
    additionalCharges,
    companyId,
    trackEvent,
    isTapNgoLoad,
    isSpotLoad,
    isPaidBookingRequired,
    formikConfig,
    loadData,
    bookingAmount = 0,
    enablePriceSuggestion
}: AssuredPaymentConfirmationProps) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const autoFocusSubmitRef = useRef<HTMLDivElement>(null);

    const { data: recommendedPrice } = useGetPriceRecommendation(
        {
            destination_id: loadData?.load_detail.destination_id,
            lorry_type_id: loadData?.load_detail.lorry_type_id,
            source_id: loadData?.load_detail.source_id,
            lorry_capacity: loadData?.load_detail.quantity
        },
        {
            ...NoSuccessErrorHandler,
            enabled: Boolean(companyId) && isSpotLoad && !formikConfig?.values?.maxExpectedAmount
        }
    );

    const handleRejectAction = () => {
        closeBidForm?.();
        trackEvent?.(GA4EventNames.MARKET_PLACE["bid_ignored"]);
    };

    const handleExpectedAmount: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.target.value = removeNonNumeric(e.target.value).slice(0, 7);
        formikConfig?.setFieldValue(e.target.name, e.target.value);
    };

    const triggerFocusToEnd = () => {
        scrollToElement(autoFocusSubmitRef);
    };

    useEffect(() => {
        triggerFocusToEnd();
    }, []);

    return (
        <VerticalFlex gap={isMobileScreen ? 20 : 20}>
            {!!bidPrice && (
                <BordererCard backgroundColor={COLORS.GREY_100} borderColor={COLORS.GREY_500}>
                    <VerticalFlex alignItems="center" margin={1.6} gap={0}>
                        <Typography size="l" weight="bold" lineHeight={0.8}>
                            {getPriceString(bidPrice)}/-
                        </Typography>
                        {!!loadData?.load_detail?.meta_data?.is_best_price && <AssuredLoadBestPrice />}
                        {!!loadData?.load_detail?.advance_payment_percentage && (
                            <Typography weight="medium" color={COLORS.GREY_900}>
                                (Advance {loadData?.load_detail?.advance_payment_percentage}%)
                            </Typography>
                        )}
                    </VerticalFlex>
                </BordererCard>
            )}
            <HowAssuredLoadWorksSteps
                type="load-bid"
                bookingAmount={bookingAmount}
                isTapNgoLoad={isTapNgoLoad}
                isSpotLoad={isSpotLoad}
                isPaidBookingRequired={isPaidBookingRequired}
                triggerFocus={triggerFocusToEnd}
                loadOwnerPaid={loadData?.load_detail?.meta_data?.is_verification_amount_paid}
            />
            {formikConfig?.values?.mandateBilityConsent && (
                <VerticalFlex gap={4}>
                    <Checkbox
                        value={!!formikConfig?.values?.willProvideBility}
                        name="willProvideBility"
                        id="bility"
                        label={"I will Provide a Bilty"}
                        onChange={(e) => formikConfig?.setFieldValue(e.target.name, e.target.checked)}
                        size="md"
                        labelProps={{
                            size: "s",
                            mSize: "xs",
                            weight: "medium"
                        }}
                        alignItems="center"
                    />
                </VerticalFlex>
            )}
            {!!bookingAmount && <CoinUsageDescription bookingAmount={bookingAmount} />}
            <VerticalFlex gap={10} alignItems="center">
                {isSpotLoad && (
                    <>
                        <ExpectedPrice
                            expectedAmount={formikConfig?.values.expectedAmount}
                            expectedAmountFieldName={LoadBidFormFieldNames.expectedAmount}
                            suggestionMax={recommendedPrice?.maximum_price}
                            suggestionMin={recommendedPrice?.minimum_price}
                            onFocus={() => trackEvent?.(GA4EventNames.MARKET_PLACE["bid_amount_entered"])}
                            onChange={handleExpectedAmount}
                            quantity={loadData?.load_detail?.quantity}
                            id={IDs.bidForm.bidPrice}
                            errorMsg={formikConfig?.errors.expectedAmount}
                            isRequired={true}
                            autoFocus={!isMobileScreen}
                            additionalAmount={loadData?.load_detail?.meta_data?.loading_charges_detail?.charges}
                        />
                        <br />
                    </>
                )}

                <div className={Styles.buttonWrapper}>
                    {isTapNgoLoad && enablePriceSuggestion && (
                        <Chip
                            onClick={handleRejectAction}
                            radius={100}
                            outlined
                            outlineColor={COLORS.GREY_200}
                            faceColor={COLORS.GREY_700}
                            weight="medium"
                            data-chip-btn
                            disabled={disableBtn}
                        >
                            <IconWrapper height={16} width={16} svgFillColor={COLORS.GREY_700}>
                                <CrossIcon />
                            </IconWrapper>
                            No
                        </Chip>
                    )}

                    <Chip
                        color="green"
                        fillColor={COLORS.GREEN_700}
                        faceColor={COLORS.WHITE}
                        blockWidth
                        filled
                        onClick={onClickBid}
                        radius={100}
                        {...(disableBtn && {
                            disabled: disableBtn,
                            fillColor: COLORS.GREY_500
                        })}
                        ref={autoFocusSubmitRef as any}
                        data-chip-btn
                        data-chip-btn--yes
                    >
                        <IconWrapper height={16} width={16}>
                            <CheckIcon />
                        </IconWrapper>
                        {isPaidBookingRequired && bookingAmount ? `Yes, Pay ${getPriceString(bookingAmount)}` : `Yes`}
                    </Chip>
                </div>
            </VerticalFlex>
        </VerticalFlex>
    );
};

export default AssuredPaymentConfirmation;
