import React, { useContext } from "react";
import Styles from "./HowAssuredLoadWorksSteps.module.scss";

// Components
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex, { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
// Constants
import { AppContext } from "@vahak/core/dist/app-context";
// Methods
import { getPriceString } from "@vahak/core-ui/dist/methods";
// Hooks
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks";
// Assets
import ArrowRightIcon from "@vahak/core/dist/icons/ArrowRightCircleBlack.svg";
// =============================== * =============================== * =============================== //

interface HowAssuredLoadWorksStepsProps {
    type: "load-bid" | "lorry-bid" | "load-lorry-details";
    showHelp?: boolean;
    bookingAmount?: number;
    isTapNgoLoad?: boolean;
    isSpotLoad?: boolean;
    isPaidBookingRequired?: boolean;
    triggerFocus?: Function;
    loadOwnerPaid?: boolean;
}

type IListItem = {
    ix?: number;
    content: React.ReactNode;
};

const ListItem = ({ content, ix }: IListItem) => {
    return (
        <span className={Styles.listItem}>
            <span className={Styles.count}>{ix}</span>
            <span className={Styles.content}>{content}</span>
        </span>
    );
};

const getLoadBidList = (meta: Partial<HowAssuredLoadWorksStepsProps>) => {
    const { bookingAmount: amount = 0, isPaidBookingRequired, isSpotLoad, isTapNgoLoad, loadOwnerPaid } = meta ?? {};

    if (isTapNgoLoad) {
        return loadOwnerPaid
            ? [
                  {
                      content: "Pay the deposit to confirm."
                  }
              ]
            : [
                  {
                      content: "Pay the deposit to book the load."
                  },
                  {
                      content: "Get confirmed within 30 minutes or get your money back."
                  }
              ];
    } else if (isSpotLoad) {
        return [
            {
                content: `This is a spot load`
            },
            {
                content: `Enter your price and pay  ${
                    amount ? getPriceString(amount) : "booking amount"
                } to instantly confirm your vehicle`
            },
            {
                content: `Get trip confirmation within 30 minutes, or your payment will be fully refunded`
            }
        ];
    } else return [];
};

const LoadLorryDetails = [
    "You accept the bid or the lorry person accepts your bid.",
    "You pay the security deposit within the given time showing your interest in this transaction.",
    "Lorry person too will pay the security deposit showing his interest.",
    "If both have paid the security deposit within the given time, your transaction will proceed to next phase.",
    "If the lorry person doesn’t show interest, your security deposit will be refunded within 24 hours and all of your older bids will be reactivated so that you can accept other bid."
];

const HowAssuredLoadWorksSteps = ({
    type,
    bookingAmount = 0,
    isPaidBookingRequired,
    isSpotLoad,
    isTapNgoLoad,
    triggerFocus,
    loadOwnerPaid
}: HowAssuredLoadWorksStepsProps) => {
    const { isMobile } = useContext(AppContext);
    const showExpandOption = isMobile;
    const expandMenuState = useToggleModalWithLocalState(!showExpandOption);

    return (
        <div className={Styles.main}>
            <Flex alignItems="center" gap={20}>
                <Typography weight="semibold" size="md" mSize="m">
                    {isTapNgoLoad ? "HOW IT WORKS?" : "How spot load works?"}
                </Typography>
                {showExpandOption && (
                    <IconWrapper
                        width={24}
                        height={24}
                        onClick={() => {
                            expandMenuState?.toggleModal();
                            !expandMenuState?.isModalOpen && triggerFocus?.();
                        }}
                        rotate={expandMenuState?.isModalOpen ? "90deg" : "-90deg"}
                    >
                        <ArrowRightIcon />
                    </IconWrapper>
                )}
            </Flex>

            {expandMenuState?.isModalOpen && (
                <>
                    <VerticalFlex className={Styles.list}>
                        {type === "load-bid" &&
                            getLoadBidList({
                                bookingAmount,
                                isPaidBookingRequired,
                                isSpotLoad,
                                isTapNgoLoad,
                                loadOwnerPaid
                            }).map((item, ix) => (
                                <Typography weight="medium" key={ix}>
                                    <ListItem content={item?.content} ix={ix + 1} key={ix} />
                                </Typography>
                            ))}

                        {type === "load-lorry-details" &&
                            LoadLorryDetails.map((item, ix) => <ListItem content={item} ix={ix + 1} key={ix} />)}
                    </VerticalFlex>
                </>
            )}
        </div>
    );
};

export default HowAssuredLoadWorksSteps;
