import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import API from "@vahak/core/dist/_services/const";
import { ClientHookOptions, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { generateQueryString } from "@vahak/core/dist/methods/generateQueryString";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
interface GetCashbackLimitResponse {
    data?: {
        cashback_usage_percentage?: number;
    };
}

interface GetCashbackLimitParams {}

export const useGetCashbackLimit = (
    params: GetCashbackLimitParams,
    options: ClientHookOptions<GetCashbackLimitResponse, unknown, GetCashbackLimitResponse>
) => {
    const {} = params;
    const { GET } = useVahakHttpClient();
    const method = () => {
        const payload = {};
        const urlString = generateQueryString(payload);
        return GET<GetCashbackLimitResponse>(`${API.GET_CASHBACK_USAGE_LIMIT}${urlString}`);
    };
    return useVahakQuery<GetCashbackLimitResponse, ApiErrorResponseType, GetCashbackLimitResponse>(
        [QueryNames.payment.cashBackLimit, params],
        method,
        {
            ...options,
            select: (data) => {
                return data;
            }
        }
    );
};
