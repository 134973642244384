import * as Yup from "yup";
import { LorryNumRegExp } from "@vahak/core/dist/constants/regex";
import { LoadBidFormFieldNames } from "../../common/fieldNames";
import { getPriceString } from "@vahak/core-ui/dist/methods";

export const BidFormValidationSchema = Yup.object().shape({
    [LoadBidFormFieldNames.lorryNum]: Yup.string()
        .matches(LorryNumRegExp, "Please enter a valid Lorry Number")
        .min(7, "Please enter a valid Lorry Number")
        .max(11, "Please enter a valid Lorry Number"),
    [LoadBidFormFieldNames.expectedAmount]: Yup.number().when(
        [LoadBidFormFieldNames.maxExpectedAmount],
        (...args: any) => {
            const [maxExpectedAmount] = args;

            let numberValidation = Yup.number()
                .positive("")
                .required("Required!")
                .min(1000, "The bid amount is too low.");
            if (maxExpectedAmount) {
                return numberValidation.max(
                    maxExpectedAmount - 1,
                    `Enter price less than ${getPriceString(maxExpectedAmount)}`
                );
            }
            return numberValidation;
        }
    ),
    [LoadBidFormFieldNames.willProvideBility]: Yup.boolean().when(LoadBidFormFieldNames.mandateBilityConsent, {
        is: (mandateBilityConsent: boolean) => mandateBilityConsent === true,
        then: Yup.boolean().oneOf([true], "Required"),
        otherwise: Yup.boolean()
    })
});
