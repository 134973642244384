import Input, { InputChangeHandler } from "@vahak/core-ui/dist/components/Input";
import ModalOrBottomSheetWrapper, {
    ModalOrBottomSheetWrapperProps
} from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import React, { useContext, useState } from "react";
import { Rupee } from "@vahak/core-ui/dist/constants";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { getPriceString, removeNonNumeric, triggerScrollOnInputFocus } from "@vahak/core-ui/dist/methods";
import { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import { BID_ACTIONS, BiddingFormContext } from "../../../../../../BiddingFormContextProvider";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { getStarBulletList } from "../../../../../posting/load-posting/load-verification/disclaimer/Disclaimer";
import useRequestCallBack from "../../../../../../services/useRequestCallBack";

interface PreferredAmountFormProps extends Pick<ModalOrBottomSheetWrapperProps, "isOpen"> {
    expectedPrice: number;
    auctionPrice: number;
    onSubmit: (price: number) => void;
    quantity?: number;
}

const PreferredAmountForm = ({ onSubmit, expectedPrice, isOpen, auctionPrice, quantity }: PreferredAmountFormProps) => {
    const { dispatchBid } = useContext(BiddingFormContext);
    const [errMsg, setErrMsg] = useState("");

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    const name = "preferred_price";
    const [price, setPrice] = useState("");

    const handleChange: InputChangeHandler = (e) => {
        setErrMsg("");
        const inputAmt = Number(removeNonNumeric(e.target.value).slice(0, 7));
        setPrice(inputAmt.toString());

        if (expectedPrice && Number.isInteger(inputAmt)) {
            if (inputAmt <= expectedPrice) {
                setErrMsg(`Please enter price greater than ${getPriceString(expectedPrice)}`);
            } else if (!!auctionPrice && Number(inputAmt) >= auctionPrice) {
                setErrMsg(`Please enter price lesser than ${getPriceString(auctionPrice)}`);
            }
        } else {
            setErrMsg("Enter valid amount");
        }
    };
    const resetForm = () => {
        dispatchBid({
            type: BID_ACTIONS.LOAD_PREFERRED_AMT,
            payload: { id: 0, open: false }
        });
    };

    const handleFormSubmit = async () => {
        await onSubmit(Number(price));
    };

    const canContinue = !!price && Number(price) > Number(expectedPrice) && !errMsg;
    const { CallBackForm, openCallBackReqModal } = useRequestCallBack({ src: "load-price-suggestion" });

    return (
        <ModalOrBottomSheetWrapper onToggle={resetForm} isOpen={isOpen} titleText="Suggest your price" modalSize="fit">
            <Typography weight="semibold">How It works?</Typography>
            {getStarBulletList([
                "Enter your price and click suggest price to submit your bid",
                "We’ll notify the load owner of your suggested price",
                "Load owner can accept your price or reject it within 30 mins"
            ])}
            <br />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleFormSubmit();
                }}
                style={{ width: isMobileScreen ? "100%" : 560 }}
            >
                <VerticalFlex gap={32}>
                    <VerticalFlex gap={20}>
                        <Typography weight="medium" size="sm">
                            Given load fixed price : {getPriceString(expectedPrice)}/-
                        </Typography>
                        <Input
                            name={name}
                            id={name + "_txtf"}
                            value={(Number(price) ? Number(price)?.toLocaleString("en-in") : "") as any}
                            type="number"
                            placeholder=""
                            inputMode="numeric"
                            onChange={handleChange}
                            prefixNode={
                                <Typography color={COLORS.BLACK} weight="medium">
                                    {Rupee}
                                </Typography>
                            }
                            suffixNode={<Typography>{`| For ${quantity} Tonne`}</Typography>}
                            weight="medium"
                            autoFocus
                            errorMsg={errMsg}
                            onFocus={triggerScrollOnInputFocus}
                        />
                    </VerticalFlex>
                    <Button type="submit" blockBtn onClick={handleFormSubmit} disabled={!canContinue} isRounded>
                        Suggest Price
                    </Button>
                </VerticalFlex>
            </form>
        </ModalOrBottomSheetWrapper>
    );
};

export default PreferredAmountForm;
